<template lang="pug">
  Modal(
    :mask-closable="true"
    :style="{top: '50px', margin: '0px auto 50px'}"
    :value="visible"
    @on-visible-change="visibleChange"
    @on-cancel="onClose"
  )
    p(slot="header")
      span {{ $t("Pool setting") }}

    Form.modal-editor(
      :model="formData"
      ref="poolSettingEditorForm"
    )
      template(v-if="formData")
        .content-part
          FormItem(
            v-if="formData.type === 'game'"
          )
            Row
              Col.title(span="9")
                h3 {{$t('Game name')}}
              Col.val(span="14" offset="1")
                span {{ formData.name }}

          FormItem
            Row
              Col.title(span="9")
                h3 {{ $t('Lottery prize') }}
              Col.val(span="14" offset="1")
                InputNumber(
                  long
                  :step="10000"
                  v-model="formData.lottery"
                  controls-outside
                )

          FormItem
            Row
              Col.title(span="9")
                h3 {{ $t('Reset prize') }}
              Col.val(span="14" offset="1")
                InputNumber(
                  long
                  :step="10000"
                  v-model="formData.prize"
                  controls-outside
                )

    .footer(slot='footer')
      Button(
        icon="md-close"
        @click="onClose"
        :disabled="isUpdating"
      ) {{ $t('Cancel') }}

      Button(
        icon="md-checkmark"
        type="primary"
        @click="handleSubmit"
        :loading="isUpdating"
      ) {{ $t('Confirm') }}

</template>
<script>
import FileUpload from '~m/file-upload'
export default {
  name: 'pool-setting-editor',
  mixins: [FileUpload],
  props: {
    formData: Object,
    editType: String,
    onClose: Function,
    onUpdate: Function,
    visible: Boolean
  },
  data () {
    return {
      isUpdating: false
    }
  },
  watch: {
    formData: {
      handler (nv, ov) {
        if (!this.formData) return
        if (this.formData && this.formData.isReady && !this.formData.isDirty) {
          this.formData.isDirty = this.formData.isReady && !this.formData.isDirty
        }
      },
      deep: true
    }
  },
  methods: {
    async visibleChange (boolean) {
      if (!boolean) {
        this.isUpdating = false
        return this.onClose()
      }
      this.formData.isReady = true
    },
    handleSubmit () {
      this.$refs.poolSettingEditorForm.validate(valid => {
        if (!valid) return false
        this.isUpdating = true
        this.onUpdate()
      })
    }
  },
  async mounted () {
  }
}
</script>
<style lang="sass">
.full-width
  width: 100%
.poolSetting
  padding: 0 10px
.uploader
    margin-right: 8px
    margin-bottom: 8px
    width: 100%

.upload-btn
  width: 130px
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
</style>
