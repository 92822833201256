<template lang="pug">
.content(
  v-infinite-scroll="fetchData",
  infinite-scroll-disabled="isUserBusy",
  :infinite-scroll-distance="scrollDistance",
  infinite-scroll-immediate-check="false"
)
  Content.app-content
    Row
      Col(span='24')
        Select.app-tool(
          v-model="provider"
          style="width:180px; margin-right:10px"
          prefix="ios-cube"
          :placeholder="$t('Please select provider')"
          @on-change="onProviderChange"
          filterable
        )
          Option(
            v-for="item in allProviderOptions"
            :value="item.value"
            :key="item.value"
          ) {{ item.label }}

    //- 主表單內容
    Row(:style="{marginTop: '16px'}")
      Col(span='24', v-if="poolList.length || !poolListLoading")
        PoolSettingTable(
          :list-data="poolList"
          :operator="`${operator}`"
        )
      Col(span='24', class="table-loading-col", v-if="poolListLoading")
        Spin(fix)
          Icon(type="ios-loading", size=18 class="spin-icon")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PoolSettingTable from './pool-setting-table'
import Scroll from '~m/scroll'
import Currency from '~m/currency'
import NumberUtil from '~m/number-util'
import RouterEvents from '~m/router-events'
import to from 'await-to-js'

export default {
  name: 'pool-setting',
  components: {
    Scroll,
    PoolSettingTable
  },

  mixins: [Scroll, Currency, NumberUtil, RouterEvents],

  // view 裡大部份的 data 是用來做過濾器用的，主要是同步 url 上的參數，部份 data 是做過濾器的暫存
  data () {
    return {
      poolData4Update: null,
      poolEditorVisible: false
    }
  },

  watch: {
    // 監聽網址的變化進行 data 重設
    async '$route' (nv, ov) {
      if (nv.params.provider === ov.params.provider) return this.fetchData(true)
      // pid 不一樣要更新 provider list
      this.resetSelector()
    }
  },

  computed: {
    ...mapGetters('poolSetting', {
      poolList: 'getListData',
      poolListLoading: 'getLoadingStatus',
      hasNextPool: 'hasNext'
    }),

    // 遊戲內容是否在讀取
    isPoolBusy () {
      return this.poolListLoading || !this.hasNextPool
    },

    isPoolOneBusy () {
      return this.poolOneLoading
    }
  },

  methods: {
    ...mapActions('poolSetting', { findPool: 'find', updatePool: 'update', resetPool: 'reset' }),

    // infinity scroll 需綁定
    fetchData (reset = false) {
      let { query } = this.$route
      let { provider, operator, gameClass } = query

      if (this.selectedOperator && !operator) {
        return this.onOperatorChange(this.selectedOperator.id)
      }

      operator = operator === 'all' ? '' : operator
      provider = provider || this.allProviderOptions[0].value
      provider = provider === 'all' ? null : provider
      gameClass = gameClass || '0'

      if (reset) {
        this.resetPool({ provider, operator })
      }

      if (this.isPoolBusy) return
      this.findPool({ provider, operator, gameClass })
    },

    // 依據 route 重設所有過濾條件
    async resetSelector () {
      let { provider, gameClass } = this.$route.query
      this.provider = !provider ? this.allProviders[0].value : Number(provider)
      this.gameClass = !gameClass ? '0' : gameClass

      this.fetchData(true)
    },

    editPool (row) {
      this.poolData4Update = _.cloneDeep(row)
      this.poolEditorVisible = true
    },

    async onPoolUpdate () {
      if (!this.poolData4Update.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closePoolEditor()
      }

      let dataUpdate = _.pick(this.poolData4Update, ['minStake', 'maxStake', 'releaseLevel', 'odds', 'category', 'initialLevel', 'actived', 'prize'])

      // 強轉數字類型
      this.parseObjectNumber(dataUpdate)

      let [err] = await to(this.updatePool({ id: this.poolData4Update.poolId, data: dataUpdate }))
      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Edit pool error'))
      }

      this.poolData = {
        ...this.poolData,
        ...dataUpdate
      }

      this.$Message.success(this.$t('Edit pool seccess'))
      // this.findGame(data)
      this.closePoolEditor()
    },

    closePoolEditor () {
      this.poolEditorVisible = false
      setTimeout(() => {
        this.poolData4Update = null
      }, 500)
    }
  },
  async mounted () {
    this.resetSelector()
  },
  beforeDestroy () {
    this.resetPool()
  }
}

</script>

<style lang="sass">
</style>
