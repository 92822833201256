<template lang="pug">
div
  .table
    AppTable.app-table(:columns="columns", :data="listData", border)
      template(
        slot-scope="{ row, index }"
        slot="info"
      )
        Avatar(
          :src="getGameThumb(row, userInfo)"
          shape="square"
          size='small'
          :style="{marginRight: '8px'}"
          v-if="row.type !== 'jp5'"
        )
        span {{ row.type === "jp5" ? $t('Game lobby') : row.name }}

      template(
        slot-scope="{ row, index }"
        slot="type"
      )
        span {{ $t(row.type) }}

      template(
        slot-scope="{ row, index }"
        slot="actived"
      )
        Icon.checked(
          type="md-checkmark"
          v-if="row.actived"
          size="18")
        Icon.close(
          type="md-close"
          v-if="!row.actived"
          size="18")

      template(
        slot-scope="{ row, index }"
        slot="actions"
      )
        Button(
          type="success",
          size='small',
          icon="md-create",
          @click="editPool(row)"
        )

  PoolEditor(
    :login-user-info="userInfo",
    :form-data="poolData4Update",
    :edit-type="poolEditorType"
    :on-update="onPoolUpdate"
    :visible="PoolEditorVisible"
    :on-close="closePoolEditor"
  )

</template>
<script>
import Currency from '~m/currency'
import Thumbnail from '~m/thumbnail'
import Time from '~m/time'
import AppTable from '~c/app-table'
import PoolEditor from './pool-editor'
import { mapActions, mapGetters } from 'vuex'
import to from 'await-to-js'

export default {
  name: 'pool-setting-table',
  mixins: [
    Currency,
    Thumbnail,
    Time
  ],
  components: {
    AppTable,
    PoolEditor
  },
  data () {
    return {
      poolData4Update: {},
      poolEditorType: null,
      PoolEditorVisible: false,
      gameSyncInfoId: null
    }
  },
  props: {
    listData: Array,
    operator: {
      type: String,
      default: 'all'
    }
  },
  watch: {
    selectedPool (nv, ov) {
      if (!nv) return
      const { totalIn, totalOut, levelInOut } = nv
      this.poolData4Update = {
        ...this.poolData4Update,
        totalIn,
        totalOut,
        levelInOut
      }
    }
  },
  computed: {
    ...mapGetters('game', {
      selectedPool: 'getOneData'
    }),

    // 嵌入 auth 的 state 資料
    ...mapGetters('auth', [
      'userInfo'
    ]),

    // 遊戲表單
    columns () {
      let columns = [
        // {
        //   title: 'ID',
        //   key: 'gameId',
        //   width: 64,
        //   align: 'center'
        // },
        {
          title: this.$t('Game name'),
          slot: 'info',
          ellipsis: true,
          minWidth: 70,
          fixed: window.innerWidth <= 400 ? 'left' : ''
        },
        {
          title: this.$t('Pool type'),
          slot: 'type',
          ellipsis: true,
          minWidth: 70
        },
        {
          title: this.$t('Real release level'),
          key: 'releaseLevel',
          ellipsis: true,
          minWidth: 110
        },
        {
          title: this.$t('Real mini stake'),
          key: 'minStake',
          ellipsis: true,
          minWidth: 110
        },
        {
          title: this.$t('Real prize'),
          key: 'realPrize',
          ellipsis: true,
          minWidth: 110
        },
        {
          title: this.$t('Fake prize'),
          key: 'prize',
          align: 'center',
          width: 135
        },
        {
          title: this.$t('Pool switch'),
          slot: 'actived',
          align: 'center',
          width: 75
        },
        {
          title: this.$t('Table actions'),
          slot: 'actions',
          width: 45
        }
      ]

      return columns
    }
  },
  mounted () {
    //
  },
  methods: {
    ...mapActions('poolSetting', {
      poolSettingUpdate: 'update'
    }),

    async onPoolUpdate () {
      if (!this.poolData4Update.isDirty) {
        this.$Message.success(this.$t('Data no change'))
        return this.closePoolEditor()
      }

      let data = _.pick(this.poolData4Update, ['prize', 'lottery'])
      data.operator = this.operator
      data.provider = this.$route.query.provider
      let [err] = await to(this.poolSettingUpdate({ id: this.poolData4Update._id, data }))
      if (err) {
        console.log(err)
        return this.$Message.error(this.$t('Edit fail'))
      }

      this.$Message.success(this.$t('Edit success'))
      this.closePoolEditor()
    },

    async editPool (row) {
      this.poolEditorType = 'edit'
      this.PoolEditorVisible = true
      this.poolData4Update = {
        _id: row._id,
        prize: 0,
        lottery: 0
      }
    },

    closePoolEditor () {
      this.PoolEditorVisible = false
      clearInterval(this.gameSyncInfoId)
      setTimeout(() => {
        this.poolEditorType = null
        this.poolData4Update = null
      }, 500)
    }
  }
}
</script>
<style lang="sass" scoped>
.current-rtp
  // font-weight: bold
  color: #ed4014

.target-rtp
  color: #c5c8ce

.rtp-divider
  margin: 0 4px

.checked
  color: #ed4014
  // color: #19be6b

.pointer
  color: #2b85e4
  cursor: pointer
  &:hover
    color: #5cadff
  &:active
    color: #2d8cf0
</style>
